import React, { CSSProperties, useEffect, useRef, useState } from "react";

import { Select } from "antd";
import { useDebounce, useToggle } from "react-use";
import unionBy from "lodash-es/unionBy";
import isObject from "lodash-es/isObject";
import { useTranslation } from "react-i18next";
import { PartialListDto } from "../eGate-API";
import { ValueSelectItem } from "./ValueSelect";
import { MemberItem } from "./MemberItem";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useInView } from "react-intersection-observer";

const PAGE_SIZE = 200;

interface ValueSelectInfiniteProps {
  /**
   * Data source
   */
  dataSource?: ValueSelectItem[];

  onChange?: (item: ValueSelectItem | undefined) => void;
  onClick?: () => void;
  onBlur?: (item: ValueSelectItem | undefined) => void;
  useIdAsValue?: boolean;
  style?: CSSProperties;
  value?: any;
  disabled?: boolean;
  id?: string;
  autoFocus?: boolean;
  allowClear?: boolean;
  className?: string;
  defaultOpen?: boolean;
  apiFunction?: (
    filter: {
      skip: number;
      max: number;
      fulltext?: string;
    },
    initialValue?: any,
    ac?: AbortController
  ) => Promise<PartialListDto<MemberItem>>;
  placeholder?: string;
  filter?: any;
  excludeItems?: ValueSelectItem[];
  disabledItems?: ValueSelectItem[];
  size?: SizeType;
  mode?: "multiple" | "tags";
  autoFill?: boolean;
  all?: boolean;
  disableSearch?: boolean;
  initialValue?: any;
}

function getValue(val: any) {
  if (typeof val === "object") {
    if (val.name) {
      return { name: val.name };
    }
    return { name: val.id };
  }
  return { name: val };
}

function findItemByValue(value: ValueSelectItem, dataSource: ValueSelectItem[]) {
  if (isObject(value)) {
    return dataSource.find((item) => item.id === value?.id) ?? getValue(value);
  }
  return dataSource.find((item) => item.id === value) ?? getValue(value);
}

export default function ValueSelectInfinite(props: ValueSelectInfiniteProps) {
  const { t } = useTranslation();
  const previousController = useRef<AbortController>();

  const [loadingMore, setLoadingMore] = useToggle(false);
  const [_currentOptions, setCurrentOptions] = useState<ValueSelectItem[]>(props.dataSource ?? []);

  useEffect(() => {
    setCurrentOptions(props.dataSource ?? []);
  }, [JSON.stringify(props.dataSource)]);

  const filteredOptions = _currentOptions?.filter((i) => !props.excludeItems?.find((v) => v.id === i.id)) ?? [];

  const [skip, setSkip] = useState<number>(0);
  const [fulltext, setFulltext] = useState<string | undefined>();

  const [reachedTheTop, setReachedTheTop] = useState<boolean>(false);
  const prevFilteredOptions = useRef<ValueSelectItem[]>();
  function fetchMore2() {
    if (!props.apiFunction) {
      return;
    }
    setLoadingMore(true);

    props
      ?.apiFunction?.(
        {
          skip,
          max: PAGE_SIZE,
          fulltext: fulltext != "" ? fulltext : undefined,
        },
        props.initialValue
      )
      .then((result: PartialListDto<MemberItem>) => {
        setLoadingMore(false);
        if (skip + PAGE_SIZE > result.rowCount) {
          setReachedTheTop(true);
        }
        if (result?.rowCount !== result?.results?.length) {
          setCurrentOptions((realCurrentOptions) => unionBy(realCurrentOptions, result.results, "id"));
        } else if (result?.rowCount === result?.results?.length) {
          setCurrentOptions(result.results);
        }
        /**
         * If the result contains only one record it will be filled in
         */
        if (props.autoFill) {
          if (result.rowCount === 1 && result.results.length === 1) {
            props?.onChange?.(result.results[0]);
          }
        }
      });
  }

  const strFilter = JSON.stringify(props.filter);

  useEffect(() => fetchMore2(), [skip, fulltext, strFilter, props.autoFill]);

  function onFulltextChange(text: string | undefined) {
    if (previousController.current) {
      previousController.current.abort();
    }
    previousController.current = new AbortController();
    setSkip(0);
    setFulltext(text);
    setReachedTheTop(false);

    prevFilteredOptions.current = [];
  }

  function extractValue() {
    if (!props.value) {
      return undefined;
    }
    if (props.mode == "multiple") {
      return props?.value?.map((i: any) => filteredOptions.find((j) => j.id == (i.id ?? i))).map((i: any) => i?.id);
    }
    return findItemByValue(props.value, filteredOptions);
  }

  //checks if the window is scrolled
  const [ref, inView] = useInView({ threshold: 0 });

  useEffect(() => {
    if (inView) {
      setSkip((s) => s + PAGE_SIZE);
    }
  }, [inView]);
  const value = extractValue();

  function getOptions() {
    const opts = filteredOptions.map((i) => {
      const isDisabled = props.disabledItems?.find((v) => v.id === i.id);
      return {
        value: i.id,
        label: (
          <div>
            <div>{i.name}</div>
            {i.description && <div style={{ color: "#777" }}>{i.description}</div>}
          </div>
        ),
        disabled: !!isDisabled,
      };
    });
    const needsLoadMore = props.apiFunction && !reachedTheTop && filteredOptions.length >= PAGE_SIZE;

    if (needsLoadMore && props.all) {
      return [{ value: "", label: t("All") }, ...opts, { value: -1, label: <div ref={ref} style={{ height: 5 }} /> }];
    }
    if (needsLoadMore) {
      return [...opts, { value: -1, label: <div ref={ref} style={{ height: 5 }} /> }];
    }

    return opts;
  }

  return (
    <Select
      allowClear={props.allowClear ?? true}
      autoClearSearchValue={true}
      autoFocus={props.autoFocus}
      className={props.className}
      data-cy="value-select"
      defaultOpen={props.defaultOpen}
      disabled={props.disabled}
      filterOption={false}
      id={props.id}
      listHeight={350}
      loading={loadingMore}
      maxTagCount={"responsive"}
      mode={props.mode}
      options={getOptions()}
      placeholder={props.placeholder}
      searchValue={fulltext}
      showSearch={!props.disableSearch}
      style={{ minWidth: "5rem", ...props.style }}
      value={props.mode === "multiple" ? value : value?.name}
      onChange={(v: any) => {
        onFulltextChange(undefined);
        if (props.mode === "multiple") {
          const idArr = v.map((id: number) => filteredOptions.find((i) => i.id === id)?.id);
          props?.onChange?.(idArr);
        } else if (props.useIdAsValue) {
          props?.onChange?.(v);
        } else {
          const item = filteredOptions.find((i) => i.id === v);
          if (item) {
            props?.onChange?.({ ...item, id: item.id, name: item.name });
          } else {
            props?.onChange?.(undefined);
          }
        }
      }}
      onSearch={onFulltextChange}
    />
  );
}
