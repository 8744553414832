import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../components/NotFound";
import Entities from "../../Entities";

function retry(fn: any, retriesLeft = 3, interval = 700): Promise<any> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

// Lazy loaded components
const Dasboard = lazy(() => retry(() => import("../../Dashboard/Dashboard")));
const About = lazy(() => retry(() => import("./About")));
const ServiceCockpit = lazy(() => retry(() => import("../../ServisCockpit/ServiceDashboard")));
const CustomerView = lazy(() => retry(() => import("../../Customer/CustomerView")));
const CustomerForm = lazy(() => retry(() => import("../../Customer/CustomerForm")));
const Customers = lazy(() => retry(() => import("../../Customer/Customers")));

const CustomerFeatures = lazy(() => retry(() => import("../../CustomerFeature/CustomerFeatures")));
const CustomerFeatureView = lazy(() => retry(() => import("../../CustomerFeature/CustomerFeatureView")));
const CustomerFeatureForm = lazy(() => retry(() => import("../../CustomerFeature/CustomerFeatureForm")));
const CustomerEulaView = lazy(() => retry(() => import("../../CustomerEula/CustomerEulaView")));
const CustomerEulaForm = lazy(() => retry(() => import("../../CustomerEula/CustomerEulaForm")));

const AppMessageView = lazy(() => retry(() => import("../../AppMessage/AppMessageView")));
const AppMessageForm = lazy(() => retry(() => import("../../AppMessage/AppMessageForm")));
const AppMessages = lazy(() => retry(() => import("../../AppMessage/AppMessages")));
const ContainerTabs = lazy(() => retry(() => import("../../WasteContainers/ContainerTabs")));
const ContainerViewTabs = lazy(() => retry(() => import("../../WasteContainers/ContainerViewTabs")));
const Containers = lazy(() => retry(() => import("../../WasteContainers/Containers")));
const BulkFirmwareAssign = lazy(() => retry(() => import("../../Firmware/BulkFirmwareAssign")));
const ContainerImportView = lazy(() => retry(() => import("../../WasteContainers/ContainerImportView")));
const ContractView = lazy(() => retry(() => import("../../Contracts/ContractView")));
const ContractImportView = lazy(() => retry(() => import("../../Contracts/ContractImportView")));
const ContractForm = lazy(() => retry(() => import("../../Contracts/ContractForm")));
const Contracts = lazy(() => retry(() => import("../../Contracts/Contracts")));
const Transactions = lazy(() => retry(() => import("../../Transactions/Transactions")));
const TransactionsCharts = lazy(() => retry(() => import("../../Transactions/Charts/TransactionsCharts")));
const Issue = lazy(() => retry(() => import("../../Issues/IssueView")));
const Issues = lazy(() => retry(() => import("../../Issues/Issues")));
const EndTests = lazy(() => retry(() => import("../../EndTest/EndTests")));
const IssuesCharts = lazy(() => retry(() => import("../../Issues/Charts/IssuesCharts")));
const ContainerMap = lazy(() => retry(() => import("../../WasteContainers/ContainerMap")));
const ProfileView = lazy(() => retry(() => import("../../Profile/ProfileView")));
const UserView = lazy(() => retry(() => import("../../User/UserView")));
const UserForm = lazy(() => retry(() => import("../../User/UserForm")));
const Users = lazy(() => retry(() => import("../../User/Users")));

const Technicians = lazy(() => retry(() => import("../../Technician/Technicians")));

const FillLevelSensorView = lazy(() => retry(() => import("../../FillLevelSensor/FillLevelSensorView")));
const FillLevelSensorForm = lazy(() => retry(() => import("../../FillLevelSensor/FillLevelSensorForm")));
const FillLevelSensors = lazy(() => retry(() => import("../../FillLevelSensor/FillLevelSensors")));
const PermissionGroupView = lazy(() => retry(() => import("../../PermissionGroups/PermissionGroupView")));
const PermissionGroupForm = lazy(() => retry(() => import("../../PermissionGroups/PermissionGroupForm")));
const PermissionGroups = lazy(() => retry(() => import("../../PermissionGroups/PermissionGroups")));

const CardReaderGroupView = lazy(() => retry(() => import("../../CardReaderGroup/CardReaderGroupView")));
const CardReaderGroupForm = lazy(() => retry(() => import("../../CardReaderGroup/CardReaderGroupForm")));
const CardReaderGroups = lazy(() => retry(() => import("../../CardReaderGroup/CardReaderGroups")));

const ContainerAccessGroupView = lazy(() => retry(() => import("../../ContainerAccessGroup/ContainerAccessGroupView")));
const ContainerAccessGroupForm = lazy(() => retry(() => import("../../ContainerAccessGroup/ContainerAccessGroupForm")));
const ContainerAccessGroups = lazy(() => retry(() => import("../../ContainerAccessGroup/ContainerAccessGroups")));

const LargeListView = lazy(() => retry(() => import("../../LargeList/LargeListView")));
const LargeListForm = lazy(() => retry(() => import("../../LargeList/LargeListForm")));
const LargeLists = lazy(() => retry(() => import("../../LargeList/LargeLists")));

const RegionCodeView = lazy(() => retry(() => import("../../RegionCode/RegionCodeView")));
const RegionCodeImportView = lazy(() => retry(() => import("../../RegionCode/RegionCodeImportView")));
const RegionCodeForm = lazy(() => retry(() => import("../../RegionCode/RegionCodeForm")));
const RegionCodes = lazy(() => retry(() => import("../../RegionCode/RegionCodes")));
const NFCCards = lazy(() => retry(() => import("../../nfcCards/nfcCards")));
const NFCCardImportView = lazy(() => retry(() => import("../../nfcCards/nfcCardImportView")));
const NFCCardView = lazy(() => retry(() => import("../../nfcCards/nfcCardView")));
const NFCCardForm = lazy(() => retry(() => import("../../nfcCards/nfcCardForm")));
const NFCCardBlacklistView = lazy(() => retry(() => import("../../nfcCardsBlackList/nfcCardBlacklistView")));
const NFCCardBlacklistForm = lazy(() => retry(() => import("../../nfcCardsBlackList/nfcCardBlacklistForm")));
const NFCCardBlacklists = lazy(() => retry(() => import("../../nfcCardsBlackList/nfcCardBlacklists")));
const ProductView = lazy(() => retry(() => import("../../Product/ProductView")));
const ProductForm = lazy(() => retry(() => import("../../Product/ProductForm")));
const Products = lazy(() => retry(() => import("../../Product/Products")));

const HardwareUnitView = lazy(() => retry(() => import("../../HardwareUnit/HardwareUnitView")));
const HardwareUnits = lazy(() => retry(() => import("../../HardwareUnit/HardwareUnits")));
const HardwareUnitForm = lazy(() => retry(() => import("../../HardwareUnit/HardwareUnitForm")));
const AddressView = lazy(() => retry(() => import("../../Address/AddressView")));
const Addresses = lazy(() => retry(() => import("../../Address/Addresses")));
const AddressForm = lazy(() => retry(() => import("../../Address/AddressForm")));

const LiftingSystemView = lazy(() => retry(() => import("../../LiftingSystem/LiftingSystemView")));
const LiftingSystems = lazy(() => retry(() => import("../../LiftingSystem/LiftingSystems")));
const LiftingSystemForm = lazy(() => retry(() => import("../../LiftingSystem/LiftingSystemForm")));

const TaskListView = lazy(() => retry(() => import("../../TaskList/TaskListView")));
const TaskLists = lazy(() => retry(() => import("../../TaskList/TaskLists")));
const TaskListForm = lazy(() => retry(() => import("../../TaskList/TaskListForm")));

const TruckBodyView = lazy(() => retry(() => import("../../TruckBody/TruckBodyView")));
const TruckBodies = lazy(() => retry(() => import("../../TruckBody/TruckBodies")));
const TruckBodyForm = lazy(() => retry(() => import("../../TruckBody/TruckBodyForm")));

const TruckTypeView = lazy(() => retry(() => import("../../TruckType/TruckTypeView")));
const TruckTypes = lazy(() => retry(() => import("../../TruckType/TruckTypes")));
const TruckTypeForm = lazy(() => retry(() => import("../../TruckType/TruckTypeForm")));

const LockTypeAliases = lazy(() => retry(() => import("../../LockTypeAlias/LockTypeAliases")));

const ServiceTaskCategoryView = lazy(() => retry(() => import("../../ServiceTaskCategory/ServiceTaskCategoryView")));
const ServiceTaskCategories = lazy(() => retry(() => import("../../ServiceTaskCategory/ServiceTaskCategories")));
const ServiceTaskCategoryForm = lazy(() => retry(() => import("../../ServiceTaskCategory/ServiceTaskCategoryForm")));

const SocialServiceNotificationMessageView = lazy(() =>
  retry(() => import("../../SocialServiceNotificationMessages/SocialServiceNotificationMessageView"))
);
const SocialServiceNotificationMessages = lazy(() =>
  retry(() => import("../../SocialServiceNotificationMessages/SocialServiceNotificationMessages"))
);
const SocialServiceNotificationMessageForm = lazy(() =>
  retry(() => import("../../SocialServiceNotificationMessages/SocialServiceNotificationMessageForm"))
);
const SocialServiceActionView = lazy(() => retry(() => import("../../SocialServiceAction/SocialServiceActionView")));
const SocialServiceActions = lazy(() => retry(() => import("../../SocialServiceAction/SocialServiceActions")));
const SocialServiceActionForm = lazy(() => retry(() => import("../../SocialServiceAction/SocialServiceActionForm")));

const ServiceTaskSolutionView = lazy(() => retry(() => import("../../ServiceTaskSolution/ServiceTaskSolutionView")));
const ServiceTaskSolutions = lazy(() => retry(() => import("../../ServiceTaskSolution/ServiceTaskSolutions")));
const ServiceTaskSolutionForm = lazy(() => retry(() => import("../../ServiceTaskSolution/ServiceTaskSolutionForm")));

const ServiceTaskCauseView = lazy(() => retry(() => import("../../ServiceTaskCause/ServiceTaskCauseView")));
const ServiceTaskCauses = lazy(() => retry(() => import("../../ServiceTaskCause/ServiceTaskCauses")));
const ServiceTaskCauseForm = lazy(() => retry(() => import("../../ServiceTaskCause/ServiceTaskCauseForm")));

const ServiceTaskMaterialView = lazy(() => retry(() => import("../../ServiceTaskMaterial/ServiceTaskMaterialView")));
const ServiceTaskMaterials = lazy(() => retry(() => import("../../ServiceTaskMaterial/ServiceTaskMaterials")));
const ServiceTaskMaterialForm = lazy(() => retry(() => import("../../ServiceTaskMaterial/ServiceTaskMaterialForm")));

const ServiceAppEvents = lazy(() => retry(() => import("../../ServiceAppEvents/ServiceAppEvents")));

const EmptyingDetectionView = lazy(() => retry(() => import("../../EmptyingDetection/EmptyingDetectionView")));
const EmptyingDetections = lazy(() => retry(() => import("../../EmptyingDetection/EmptyingDetections")));
const EmptyingDetectionForm = lazy(() => retry(() => import("../../EmptyingDetection/EmptyingDetectionForm")));
const EmptyingDetectionLogs = lazy(() => retry(() => import("../../EmptyingDetectionLogs/EmptyingDetectionLogs")));
const EmptyingDetectionLogView = lazy(() => retry(() => import("../../EmptyingDetectionLogs/EmptyingDetectionLogView")));

const TruckView = lazy(() => retry(() => import("../../Truck/TruckView")));
const Trucks = lazy(() => retry(() => import("../../Truck/Trucks")));
const TruckForm = lazy(() => retry(() => import("../../Truck/TruckForm")));

const LoraSettingsView = lazy(() => retry(() => import("../../LoRaSettings/LoRaSettingsView")));
const LoraSettings = lazy(() => retry(() => import("../../LoRaSettings/LoRaSettings")));
const LoraSettingsForm = lazy(() => retry(() => import("../../LoRaSettings/LoRaSettingsForm")));

const MifareDataReadFrameView = lazy(() => retry(() => import("../../MifareDataReadFrame/MifareDataReadFrameView")));
const MifareDataReadFrame = lazy(() => retry(() => import("../../MifareDataReadFrame/MifareDataReadFrame")));
const MifareDataReadFrameForm = lazy(() => retry(() => import("../../MifareDataReadFrame/MifareDataReadFrameForm")));

const FirmwareView = lazy(() => retry(() => import("../../Firmware/FirmwareView")));
const Firmwares = lazy(() => retry(() => import("../../Firmware/Firmwares")));

const ContainerEmptyings = lazy(() => retry(() => import("../../ContainerEmptying/ContainerEmptyings")));

const BulkContainerTelemetryExport = lazy(() => retry(() => import("../../WasteContainers/BulkContainerTelemetryExport")));
const BulkContainerFillExport = lazy(() => retry(() => import("../../WasteContainers/BulkContainerFillExport")));

const IntervalConfigurationGroupView = lazy(() => retry(() => import("../../IntervalConfigurationGroup/IntervalConfigurationGroupView")));
const IntervalConfigurationGroups = lazy(() => retry(() => import("../../IntervalConfigurationGroup/IntervalConfigurationGroups")));
const IntervalConfigurationGroupForm = lazy(() => retry(() => import("../../IntervalConfigurationGroup/IntervalConfigurationGroupForm")));
const FractionConfigurationGroupView = lazy(() => retry(() => import("../../FractionConfigurationGroup/FractionConfigurationGroupView")));
const FractionConfigurationGroups = lazy(() => retry(() => import("../../FractionConfigurationGroup/FractionConfigurationGroups")));
const FractionConfigurationGroupForm = lazy(() => retry(() => import("../../FractionConfigurationGroup/FractionConfigurationGroupForm")));

const RegionCodeGroupView = lazy(() => retry(() => import("../../RegionCodeGroup/RegionCodeGroupView")));
const RegionCodeGroups = lazy(() => retry(() => import("../../RegionCodeGroup/RegionCodeGroups")));
const RegionCodeGroupForm = lazy(() => retry(() => import("../../RegionCodeGroup/RegionCodeGroupForm")));

const BIDashboardView = lazy(() => retry(() => import("../../BIDashboardManagement/BIDashboardView")));
const BIDashboards = lazy(() => retry(() => import("../../BIDashboardManagement/BIDashboards")));
const BIDashboardForm = lazy(() => retry(() => import("../../BIDashboardManagement/BIDashboardForm")));

const BIDashboardsView = lazy(() => retry(() => import("../../BIDashboards/BIDashboardsView")));

const HardwareCommands = lazy(() => retry(() => import("../../HardwareCommands/HardwareCommands")));
const HardwareCommandView = lazy(() => retry(() => import("../../HardwareCommands/HardwareCommandView")));
const HardwareCommandForm = lazy(() => retry(() => import("../../HardwareCommands/HardwareCommandForm")));
const Devices = lazy(() => retry(() => import("../../Devices/Devices")));

const ServiceCalls = lazy(() => retry(() => import("../../ServiceCalls/ServiceCalls")));
const ServiceTasks = lazy(() => retry(() => import("../../ServiceTasks/ServiceTasks")));
const ServiceTaskForm = lazy(() => retry(() => import("../../ServiceTasks/ServiceTaskForm")));
const ServiceTaskView = lazy(() => retry(() => import("../../ServiceTasks/ServiceTaskView")));
const FillContainers = lazy(() => retry(() => import("../../FillContainers/FillContainers")));
const SystemOperationLogs = lazy(() => retry(() => import("../../SystemOperationLog/SystemOperationLogs")));
const SocialServiceRunners = lazy(() => retry(() => import("../../SocialServiceRunners/SocialServiceRunners")));

const Flows = lazy(() => retry(() => import("../../Flows/Flows")));
const CustomerCreateFlow = lazy(() => retry(() => import("../../Flows/CustomerCreateFlow")));
const CustomerCreateFlowFormGeneral = lazy(() => retry(() => import("../../Flows/CustomerCreateFlowFormGeneral")));
const CustomerCreateFlowFormBranding = lazy(() => retry(() => import("../../Flows/CustomerCreateFlowFormBranding")));
const FractionCreateFlowForm = lazy(() => retry(() => import("../../Flows/FractionCreateFlowForm")));
const CustomerCreateFlowOverview = lazy(() => retry(() => import("../../Flows/CustomerCreateFlowOverview")));
const UserCreateFlowForm = lazy(() => retry(() => import("../../Flows/UserCreateFlowForm")));
const FractionCreateFlowManager = lazy(() => retry(() => import("../../Flows/FractionCreateFlowManager")));
const UserCreateFlowManager = lazy(() => retry(() => import("../../Flows/UserCreateFlowManager")));
const ContractCreateFlowForm = lazy(() => retry(() => import("../../Flows/ContractCreateFlowForm")));
const LargeListFlowForm = lazy(() => retry(() => import("../../Flows/LargeListFlowForm")));
/**
 * Defines application routes
 *
 * @param {any} props
 */
function AppRouter() {
  return (
    <Routes>
      <Route element={<Dasboard />} path={`dashboard`} />
      <Route element={<ServiceCockpit />} path={`service-cockpit`} />
      <Route element={<CustomerEulaForm />} path={`customer/:customerId/eula/:id/edit`} />
      <Route element={<CustomerEulaView />} path={`customer/:customerId/eula/:id`} />
      <Route element={<Customers />} path={`customer`} />
      <Route element={<CustomerView />} path={`customer/:id`} />
      <Route element={<CustomerForm />} path={`customer/:id/edit`} />
      <Route element={<CustomerFeatures />} path={`customer-features`} />
      <Route element={<CustomerFeatureView />} path={`customer-features/:id`} />
      <Route element={<CustomerFeatureForm />} path={`customer-features/:id/edit`} />
      <Route element={<AppMessages />} path={`app-message`} />
      <Route element={<AppMessageView />} path={`app-message/:id`} />
      <Route element={<AppMessageForm />} path={`app-message/:id/edit`} />
      <Route element={<Containers />} path={`container`} />
      <Route element={<FillContainers />} path={`fill-level-sensors-containers`} />
      <Route element={<ContainerImportView />} path={`container/import`} />
      <Route element={<ContainerViewTabs />} path={`container/:id`} />
      <Route element={<ContainerTabs />} path={`container/:id/edit`} />
      <Route element={<Contracts />} path={`contract`} />
      <Route element={<ContractImportView />} path={`contract/import`} />
      <Route element={<ContractView />} path={`contract/:id`} />
      <Route element={<ContractForm />} path={`contract/:id/edit`} />
      <Route element={<Transactions />} path={`transactions`} />
      <Route element={<TransactionsCharts />} path={`transaction-charts`} />
      <Route element={<Issues />} path={`issue`} />
      <Route element={<EndTests />} path={`end-test`} />
      <Route element={<IssuesCharts />} path={`issue-charts`} />
      <Route element={<Issue />} path={`issue/:id`} />
      <Route element={<ContainerMap />} path={`map`} />
      <Route element={<ProfileView />} path={`profile`} />
      <Route element={<PermissionGroups />} path={`permission-group`} />
      <Route element={<PermissionGroupView />} path={`permission-group/:id`} />{" "}
      <Route element={<PermissionGroupForm />} path={`permission-group/:id/edit`} />
      <Route element={<RegionCodes />} path={`region-code`} />
      <Route element={<RegionCodeImportView />} path={`region-code/import`} />
      <Route element={<RegionCodeView />} path={`region-code/:id`} />
      <Route element={<RegionCodeForm />} path={`region-code/:id/edit`} />
      <Route element={<Users />} path={`user`} />
      <Route element={<UserView />} path={`user/:id`} />
      <Route element={<UserForm />} path={`user/:id/edit`} />
      <Route element={<Technicians />} path={`technician`} />
      {/*---*/}
      <Route element={<LiftingSystems />} path={`lifting-system`} />
      <Route element={<LiftingSystemView />} path={`lifting-system/:id`} />
      <Route element={<LiftingSystemForm />} path={`lifting-system/:id/edit`} />
      {/*---*/}
      <Route element={<TaskLists />} path={`task-list`} />
      <Route element={<TaskListView />} path={`task-list/:id`} />
      <Route element={<TaskListForm />} path={`task-list/:id/edit`} />
      {/*---*/}
      <Route element={<TruckBodies />} path={`truck-body`} />
      <Route element={<TruckBodyView />} path={`truck-body/:id`} />
      <Route element={<TruckBodyForm />} path={`truck-body/:id/edit`} />
      {/*---*/}
      <Route element={<TruckTypeForm />} path={`truck-type/:id/edit`} />
      <Route element={<TruckTypes />} path={`truck-type`} />
      <Route element={<TruckTypeView />} path={`truck-type/:id`} />
      {/*---*/}
      <Route element={<ServiceTaskCategoryForm />} path={`${Entities.serviceTaskCategory.baseRoute}/:id/edit`} />
      <Route element={<ServiceTaskCategories />} path={`${Entities.serviceTaskCategory.baseRoute}`} />
      <Route element={<ServiceTaskCategoryView />} path={`${Entities.serviceTaskCategory.baseRoute}/:id`} />
      {/*---*/}
      <Route element={<SocialServiceNotificationMessageForm />} path={`${Entities.socialServicesNotificationMessages.baseRoute}/:id/edit`} />
      <Route element={<SocialServiceNotificationMessages />} path={`${Entities.socialServicesNotificationMessages.baseRoute}`} />
      <Route element={<SocialServiceNotificationMessageView />} path={`${Entities.socialServicesNotificationMessages.baseRoute}/:id`} />
      {/*---*/}
      <Route element={<SocialServiceActionForm />} path={`${Entities.socialServiceActions.baseRoute}/:id/edit`} />
      <Route element={<SocialServiceActions />} path={`${Entities.socialServiceActions.baseRoute}`} />
      <Route element={<SocialServiceActionView />} path={`${Entities.socialServiceActions.baseRoute}/:id`} />
      {/*---*/}
      <Route element={<ServiceTaskCauseForm />} path={`${Entities.serviceTaskCause.baseRoute}/:id/edit`} />
      <Route element={<ServiceTaskCauses />} path={`${Entities.serviceTaskCause.baseRoute}`} />
      <Route element={<ServiceTaskCauseView />} path={`${Entities.serviceTaskCause.baseRoute}/:id`} />
      {/*---*/}
      <Route element={<ServiceTaskSolutionForm />} path={`${Entities.serviceTaskSolution.baseRoute}/:id/edit`} />
      <Route element={<ServiceTaskSolutions />} path={`${Entities.serviceTaskSolution.baseRoute}`} />
      <Route element={<ServiceTaskSolutionView />} path={`${Entities.serviceTaskSolution.baseRoute}/:id`} />
      {/*---*/}
      <Route element={<ServiceTaskMaterialView />} path={`${Entities.serviceTaskMaterial.baseRoute}/:id`} />
      <Route element={<ServiceTaskMaterialForm />} path={`${Entities.serviceTaskMaterial.baseRoute}/:id/edit`} />
      <Route element={<ServiceTaskMaterials />} path={`${Entities.serviceTaskMaterial.baseRoute}`} />
      {/*---*/}
      <Route element={<ServiceAppEvents />} path={`${Entities.serviceAppEvents.baseRoute}`} />
      {/*---*/}
      <Route element={<EmptyingDetections />} path={`emptying-detection/`} />
      <Route element={<EmptyingDetectionView />} path={`emptying-detection/:id`} />
      <Route element={<EmptyingDetectionForm />} path={`emptying-detection/:id/edit`} />
      {/*---*/}
      <Route element={<EmptyingDetectionLogs />} path={`emptying-detection-logs`} />
      <Route element={<EmptyingDetectionLogView />} path={`emptying-detection-logs/:id`} />
      {/*---*/}
      <Route element={<Trucks />} path={`truck`} />
      <Route element={<TruckView />} path={`truck/:id`} />
      <Route element={<TruckForm />} path={`truck/:id/edit`} />
      {/*---*/}
      <Route element={<Firmwares />} path={`firmware`} />
      <Route element={<FirmwareView />} path={`firmware/:id`} />
      {/*---*/}
      <Route element={<ContainerEmptyings />} path={`container-emptying`} />
      {/*---*/}
      <Route element={<FillLevelSensors />} path={`fill-level-sensor`} />
      <Route element={<FillLevelSensorView />} path={`fill-level-sensor/:id`} />
      <Route element={<FillLevelSensorForm />} path={`fill-level-sensor/:id/edit`} />
      {/*---*/}
      <Route element={<NFCCards />} path={`nfc-card/`} />
      <Route element={<NFCCardImportView />} path={`nfc-card/import`} />
      <Route element={<NFCCardView />} path={`nfc-card/:id`} />
      <Route element={<NFCCardForm />} path={`nfc-card/:id/edit`} />
      {/*---*/}
      <Route element={<NFCCardBlacklists />} path={`nfc-card-blacklist/`} />
      <Route element={<NFCCardBlacklistForm />} path={`nfc-card-blacklist/:id/edit`} />
      <Route element={<NFCCardBlacklistView />} path={`nfc-card-blacklist/:id`} />
      {/*---*/}
      <Route element={<Products />} path={`product/`} />
      <Route element={<ProductForm />} path={`product/:id/edit`} />
      <Route element={<ProductView />} path={`product/:id`} />
      {/*---*/}
      <Route element={<HardwareUnits />} path={`hardware-unit/`} />
      <Route element={<HardwareUnitForm />} path={`hardware-unit/:id/edit`} />
      <Route element={<HardwareUnitView />} path={`hardware-unit/:id`} />
      {/*---*/}
      <Route element={<Addresses />} path={`container-address/`} />
      <Route element={<AddressForm />} path={`container-address/:id/edit`} />
      <Route element={<AddressView />} path={`container-address/:id`} />
      {/*---*/}
      <Route element={<BIDashboards />} path={`bi-dashboards-management`} />
      <Route element={<BIDashboardForm />} path={`bi-dashboards-management/:id/edit`} />
      <Route element={<BIDashboardView />} path={`bi-dashboards-management/:id`} />
      {/*---*/}
      <Route element={<BIDashboardsView />} path={`bi-dashboards`} />
      {/*---*/}
      <Route element={<IntervalConfigurationGroups />} path={`time-preset/`} />
      <Route element={<IntervalConfigurationGroupForm />} path={`time-preset/:id/edit`} />
      <Route element={<IntervalConfigurationGroupView />} path={`time-preset/:id`} />
      <Route element={<FractionConfigurationGroups />} path={`fraction-preset/`} />
      <Route element={<FractionConfigurationGroupForm />} path={`fraction-preset/:id/edit`} />
      <Route element={<FractionConfigurationGroupView />} path={`fraction-preset/:id`} />
      <Route element={<RegionCodeGroups />} path={`region-code-preset/`} />
      <Route element={<RegionCodeGroupForm />} path={`region-code-preset/:id/edit`} />
      <Route element={<RegionCodeGroupView />} path={`region-code-preset/:id`} />
      <Route element={<CardReaderGroups />} path={`card-reader-preset/`} />
      <Route element={<CardReaderGroupForm />} path={`card-reader-preset/:id/edit`} />
      <Route element={<LoraSettingsView />} path={`lora-settings/:id`} />
      <Route element={<LoraSettings />} path={`lora-settings/`} />
      <Route element={<MifareDataReadFrameForm />} path={`mifare-data-read-frame/:id/edit`} />
      <Route element={<MifareDataReadFrameView />} path={`mifare-data-read-frame/:id`} />
      <Route element={<MifareDataReadFrame />} path={`mifare-data-read-frame/`} />
      <Route element={<LargeListForm />} path={`large-list/:id/edit`} />
      <Route element={<LargeListView />} path={`large-list/:id`} />
      <Route element={<LargeLists />} path={`large-list/`} />
      <Route element={<LoraSettingsForm />} path={`lora-settings/:id/edit`} />
      <Route element={<CardReaderGroupView />} path={`card-reader-preset/:id`} />
      <Route element={<BulkContainerTelemetryExport />} path={`container-telemetry/export`} />
      <Route element={<BulkContainerFillExport />} path={`container-fill/export`} />
      <Route element={<BulkFirmwareAssign />} path={`container-firmware-assign/:id`} />
      <Route element={<ServiceCalls />} path={`service-calls`} />
      <Route element={<ServiceTasks />} path={`service-tasks`} />
      <Route element={<ServiceTaskForm />} path={`service-tasks/:id/edit`} />
      <Route element={<ServiceTaskView />} path={`service-tasks/:id`} />
      <Route element={<HardwareCommands />} path={`hardware-commands`} />
      <Route element={<HardwareCommandView />} path={`hardware-commands/:id`} />
      <Route element={<HardwareCommandForm />} path={`hardware-commands/:id/edit`} />
      <Route element={<ContainerAccessGroups />} path={`container-access-groups`} />
      <Route element={<ContainerAccessGroupView />} path={`container-access-groups/:id`} />
      <Route element={<ContainerAccessGroupForm />} path={`container-access-groups/:id/edit`} />
      <Route element={<LockTypeAliases />} path={`container-lock-type-aliases`} />
      <Route element={<SystemOperationLogs />} path={`system-operations-log`} />
      <Route element={<SocialServiceRunners />} path={`social-service-runners`} />
      {/*---*/}
      <Route element={<Flows />} path={`flows`} />
      <Route element={<CustomerCreateFlow />} path={`flows/customer`} />
      <Route element={<CustomerCreateFlowFormGeneral />} path={`flows/:id/customer/:customerId/general`} />
      <Route element={<CustomerCreateFlowFormBranding />} path={`flows/:id/customer/:customerId/branding`} />
      <Route element={<FractionCreateFlowForm />} path={`flows/:id/customer/:customerId/fraction/:fractionId`} />
      <Route element={<CustomerCreateFlowOverview />} path={`flows/:id/customer/:customerId/overview/`} />
      <Route element={<UserCreateFlowForm />} path={`flows/:id/customer/:customerId/user/:userId`} />
      <Route element={<UserCreateFlowManager />} path={`flows/:id/customer/:customerId/user`} />
      <Route element={<FractionCreateFlowManager />} path={`flows/:id/customer/:customerId/fraction`} />
      <Route element={<ContractCreateFlowForm />} path={`flows/:id/customer/:customerId/contract/:contractId/edit`} />
      <Route element={<LargeListFlowForm />} path={`flows/:id/customer/:customerId/large-list/:largeListId/edit`} />
      {/*---*/}
      <Route element={<About />} path={`about`} />
      <Route element={<Devices />} path={`device`} />
      <Route element={<NotFound />} />
    </Routes>
  );
}

export default AppRouter;
